<template>
    <v-row>
        <v-col cols="0" xs="0" sm="5" md="4" class="pa-0">
        </v-col>
        <v-col cols="12" xs="12" sm="2" md="4" class="text-center pa-0">
            <v-btn @click="prev" :disabled="isPrevDisabled" icon><v-icon>fas fa-caret-left</v-icon></v-btn>
            {{ pagination.page }}
            <v-btn @click="next" :disabled="isNextDisabled" icon><v-icon>fas fa-caret-right</v-icon></v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="5" md="4" class="mt-4 mt-sm-0 text-right pa-0 pr-3">
            <template v-if="!hidePerPage">
                <small class="text--secondary">{{ $t('$vuetify.dataIterator.rowsPerPageText') }}</small>
                <div class="per-page-select-container ml-3 mr-4">
                    <SelectInput
                        :items="availablePerPage"
                        @input="pagination.per_page = $event"
                        :value="computedPagination.per_page"
                        inline dense hide-details outlined
                        :disabled="perPageDisabled"
                    />
                </div>
            </template>
        </v-col>
    </v-row>
</template>

<script>
import SelectInput from '../inputs/SelectInput'

export default {
    name: 'SimplePaginator',
    props: {
        pagination: {
            required: true,
            type: Object,
        },
        items: {
            required: true,
            type: Array,
        },
        availablePerPage: {
            type: Array,
            default: () => [5, 10, 25, 50],
        },
        hidePerPage    : Boolean,
        isLastPage     : Boolean,   // indica que é a última página e não precisa mostrar próximo
        perPageDisabled: Boolean,   // indica se deve travar a seleção de itens por página
    },
    components: { SelectInput },
    methods: {
        next() {
            if (this.isNextDisabled)
                return

            this.pagination.page = this.computedPagination.page + 1

            this.$emit('next')
        },
        prev() {
            if (this.isPrevDisabled)
                return

            this.pagination.page = this.computedPagination.page - 1

            this.$emit('prev')
        },
    },
    computed: {
        isPrevDisabled() {
            return this.pagination.page <= 1
        },
        isNextDisabled() {
            return !this.items.length || this.items.length != this.pagination.per_page || this.isLastPage
        },
        computedPagination() {
            return {
                page    : this.$lodash.toNumber(this.pagination.page),
                per_page: this.perPageDisabled ? null : this.$lodash.toNumber(this.pagination.per_page),
            }
        },
    },
    watch: {
        pagination: {
            deep: true,
            handler(newValue) {
                this.$emit('update:pagination', newValue)
                this.$emit('update:page', newValue.page)
                this.$emit('update:per_page', newValue.per_page)
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.per-page-select-container {
    max-width: 120px;
    display: inline-block;
}
</style>