var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c("v-col", {
        staticClass: "pa-0",
        attrs: { cols: "0", xs: "0", sm: "5", md: "4" }
      }),
      _c(
        "v-col",
        {
          staticClass: "text-center pa-0",
          attrs: { cols: "12", xs: "12", sm: "2", md: "4" }
        },
        [
          _c(
            "v-btn",
            {
              attrs: { disabled: _vm.isPrevDisabled, icon: "" },
              on: { click: _vm.prev }
            },
            [_c("v-icon", [_vm._v("fas fa-caret-left")])],
            1
          ),
          _vm._v(" " + _vm._s(_vm.pagination.page) + " "),
          _c(
            "v-btn",
            {
              attrs: { disabled: _vm.isNextDisabled, icon: "" },
              on: { click: _vm.next }
            },
            [_c("v-icon", [_vm._v("fas fa-caret-right")])],
            1
          )
        ],
        1
      ),
      _c(
        "v-col",
        {
          staticClass: "mt-4 mt-sm-0 text-right pa-0 pr-3",
          attrs: { cols: "12", xs: "12", sm: "5", md: "4" }
        },
        [
          !_vm.hidePerPage
            ? [
                _c("small", { staticClass: "text--secondary" }, [
                  _vm._v(
                    _vm._s(_vm.$t("$vuetify.dataIterator.rowsPerPageText"))
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "per-page-select-container ml-3 mr-4" },
                  [
                    _c("SelectInput", {
                      attrs: {
                        items: _vm.availablePerPage,
                        value: _vm.computedPagination.per_page,
                        inline: "",
                        dense: "",
                        "hide-details": "",
                        outlined: "",
                        disabled: _vm.perPageDisabled
                      },
                      on: {
                        input: function($event) {
                          _vm.pagination.per_page = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }